import React from 'react'
import '../scss/ThisIsWhere.scss'
import { Link } from "react-router-dom";
import Url from './Url';
const graphicThisIsWhere = `${Url.cdn}/img/elements/graphic-this-is-where.svg`;

const ThisIsWhere = () => {
  return (
    <section className="ThisIsWhere">
      <div className="container">
        <img className="text-graphic" src={graphicThisIsWhere} alt="This is where your favorite superheroes unite"/>
        <div className="text-area">Discover a marvelous place where fans of all ages come together. Want to know more?</div>
        <div className="button-cont"><Link to="/this-is-comicx"><button>This is comicx</button></Link></div>
      </div>
    </section>
  )
}

export default ThisIsWhere