import React from 'react'
import { JoinUs as JoinUsComponent } from '../components'

const JoinUs = () => {
  window.scrollTo(0,0);
  return (
    <div className="JoinUsPage">
      <JoinUsComponent />
    </div>
  )
}

export default JoinUs