import React from 'react'
import {
	ThisIsComicxHeader,
	WeBelieve,
	WhereSuperheroes,
	PaperCrop
} from '../components'
import history from '../history'

const ThisIsComicx = () => {
  window.scrollTo(0,0);
  return (
    <div className='ThisIsComicx'>
			<ThisIsComicxHeader />
			<PaperCrop />
			<WeBelieve />
			<PaperCrop />
			<WhereSuperheroes />
		</div>
  )
}

export default ThisIsComicx