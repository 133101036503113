import React from 'react'
import '../scss/NewsPage.scss'
import { NewsHeader } from '../components'
import { NewsFeed } from '../components'

class News extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0)
    if (!this.props.news.length)
      this.props.loadMoreNews()
  }

  render() {
    if (this.props.news.length > 0) {
      return (
        <div className="News">
          <NewsHeader news={this.props.news[0]}/>
          <NewsFeed
            news={this.props.news.slice(1)}
            loadMoreNews={this.props.loadMoreNews}
            error={this.props.newsStatus.error}
            hasMore={this.props.newsStatus.hasMore}
            isLoading={this.props.newsStatus.isLoading}
          />
        </div>
      )
    }
    return (
      <div className="News">
        <div className="loading">
          <i className="fas fa-spinner fa-spin"/>
        </div>
      </div>
    )
  }
}

export default News