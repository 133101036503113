import React from 'react'
import debounce from 'lodash.debounce'
import { NewsBox } from './'
import '../scss/NewsFeed.scss'

class NewsFeed extends React.Component {
  constructor(props) {
    super(props)
    // Binds our scroll event handler
    window.onscroll = debounce(() => {
      const { error, isLoading, hasMore } = this.props

      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (error || isLoading || !hasMore) return

      // Checks that the page has scrolled to the bottom
      const windowHeight = window.innerHeight
      const scroll = document.documentElement.scrollTop
      const documentHeight = document.documentElement.offsetHeight
      const footerHeight = document.getElementsByClassName('Footer')[0].offsetHeight
      if (windowHeight + scroll >= documentHeight - footerHeight) {
        this.props.loadMoreNews()
      }
    }, 100);
  }

  render() {
    const { news, isLoading } = this.props

    if(!news) {
      return <div></div>
    }

    return (
      <div className="NewsFeed">
        <div className="container">
          {news.map((news, index) => 
            <NewsBox
              key={index}
              isMain={false}
              title={news.title}
              content={news.content}
              image={news.img}
            />
          )}
        </div>
        {isLoading ?
          <div className="loading">
            <i className="fas fa-spinner fa-spin"/>
          </div>
        : ''}
      </div>
    )
  }
}

export default NewsFeed