const {
  REACT_APP_CDN,
  REACT_APP_CDN_NEWS,
  REACT_APP_API,
  REACT_APP_CDN_STG,
  REACT_APP_CDN_NEWS_STG,
  REACT_APP_API_STG,
  REACT_APP_BUILD_ENV,
} = process.env

const Url = {
  cdn: REACT_APP_BUILD_ENV === 'production' ? REACT_APP_CDN : REACT_APP_CDN_STG,
  cdnNews: REACT_APP_BUILD_ENV === 'production' ? REACT_APP_CDN_NEWS : REACT_APP_CDN_NEWS_STG,
  api: REACT_APP_BUILD_ENV === 'production' ? REACT_APP_API : REACT_APP_API_STG,
}

export default Url;