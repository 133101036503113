import React from 'react'
import {
	GrabBite,
	ThisIsWhere,
	MoreInfo,
	FindUs,
	PaperCrop
} from '../components'
import history from '../history'

const Home = () => {
  window.scrollTo(0,0);
  return (
    <div className='Home'>
			<GrabBite />
			<PaperCrop />
			<ThisIsWhere />
			<PaperCrop />
			<MoreInfo />
			<PaperCrop />
			<FindUs />
		</div>
  )
}

export default Home