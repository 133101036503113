import React from 'react'
import '../scss/GrabBite.scss'
import { Link } from "react-router-dom";
import Url from './Url';
const graphicGrabBite = `${Url.cdn}/img/elements/graphic-grab-a-bite.svg`;

const GrabBite = () => {
  return (
    <section className="GrabBite">
      <div className="container">
        <img className="text-graphic" src={graphicGrabBite} alt="grab a bite "/>
        <div className="text-box">Comicx® is all about magic, fun and great food. Check out our menu and enjoy every delicious surprise we have for you.</div>
        <div className="button-cont"><Link to="/menu"><button>View Menu</button></Link></div>
      </div>
    </section>
  )
}

export default GrabBite