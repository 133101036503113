import React from 'react'
import { Link } from 'react-router-dom'
import '../scss/NewsDetails.scss'

class NewsDetailsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      news: null
    }
  }

  async componentDidMount() {
    const news = this.props.news.find(item => {
      let titleFormat = item.title.toLowerCase().replace(' ', '-', 'gi')
      return titleFormat === this.props.title
    })

    if(news) {
      this.setState({news})
    } else {
      const foundNews = await this.props.fetchNewsDetail(this.props.title)
      this.setState({news: foundNews[0]})
    }
  }

  render() {
    window.scrollTo(0,0)
    if(!this.state.news) {
      return <div></div>
    }
    const {title, content, img, createdAt} = this.state.news
    const date = new Date(createdAt)
    return (
      <div className="NewsDetailsPage">
        <div className="container">
          <img className="image" src={img} alt=""/>
          <div className="back"><Link to="/news">← Back to news</Link></div>
          <div className="content">
            <div className="title">{title}</div>
            <div className="date">{date.toDateString()}</div>
            <div className="text" dangerouslySetInnerHTML={{__html: content}}/>
          </div>
        </div>
      </div>
    )
  }
}

export default NewsDetailsPage