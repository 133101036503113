import React from 'react'
import '../scss/WeBelieve.scss'
import Url from './Url';
const graphicWeBelieve = `${Url.cdn}/img/elements/graphic-we-believe.svg`;
const graphicCharacters = `${Url.cdn}/img/elements/graphic-characters.svg`;

const WeBelieve = () => {
  return (
    <section className="WeBelieve">
      <div className="container">
        <img className="text-graphic" src={graphicWeBelieve} alt="We believe in a world of magic, fun, and great food!"/>
        <div className="box">
          <div className="box-text">In ComicX your favorite superheroes unite with the best ingredients and unique recipes to create a marvelous place to gather, eat and celebrate with family and friends.</div>
          <div className="box-image"/>
        </div>
        <div className="note">A wonderful place where fans of all ages come together and rivalry doesn’t exist, where you’ll find your favorite superhero and villain memorabilia lining our walls and you can take a picture with life-sized comic book characters before you leave.</div>
        <img className="characters" src={graphicCharacters} alt="comicx characters"/>
      </div>
    </section>
  )
}

export default WeBelieve