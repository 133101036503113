import React from 'react'
import '../scss/Menu.scss'
import Url from './Url';
const graphicGrabBite = `${Url.cdn}/img/elements/graphic-grab-bite-horizontal.svg`;
const foodMenu = `${Url.cdn}/files/ComicX_Food_Menu.pdf`;
const drinksMenu = `${Url.cdn}/files/ComicX_Drinks_Menu.pdf`;


const Menu = () => {
  return (
    <div className="Menu">
      <div className="container">
        <img className="text-graphic" src={graphicGrabBite} alt="grab a bite "/>
        <div className="food"><a href={foodMenu} target="_blank"><button>Food</button></a></div>
        <div className="drinks"><a href={drinksMenu} target="_blank"><button>Drinks</button></a></div>
      </div>
    </div>
  )
}

export default Menu