import React from 'react'
import { Link } from "react-router-dom"
import '../scss/NewsBox.scss'

const NewsBox = ({isMain, title, content, image}) => {
  return (
    <div className="NewsBox" id={isMain ? 'Main' : ''}>
      <img src={image} alt=""/>
      <div className="label">
        <div className="title">{title}</div>
        <div className="content">
          <div className="text" dangerouslySetInnerHTML={{__html: content}}/>
          <div className="button"><Link to={`/news/${title.toLowerCase().replace(' ', '-', 'gi')}`}><button>Learn More</button></Link></div>
        </div>
      </div>
    </div>
  )
}

export default NewsBox