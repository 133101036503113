import React from 'react'
import DateTimePicker from 'react-datetime-picker';
import api from '../api/api'
import '../scss/MoreInfo.scss'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Url from './Url';
const graphicSuperparties = `${Url.cdn}/img/elements/graphic-superparties.svg`;


class MoreInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      event: '',
      date: '',
      comment: '',
      errors: {
        name: false,
        email: false,
        phone: false,
        event: false,
        date: false,
        comment: false,
      },
      showCalendar: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCalendar = this.handleCalendar.bind(this);
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const field = target.name
    this.setState({[field]: value, errors: Object.assign(this.state.errors, {[field]: false})})
  }

  handleSubmit(e) {
    e.preventDefault();
    const {name, phone, email, event, date, comment} = this.state
    const body = {
      name: name || undefined, 
      phone: phone || undefined, 
      email: email || undefined, 
      event: event || undefined, 
      date: date || undefined, 
      comment: comment || undefined, 
    };
    api.post('/events', body, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Referrer-Policy': 'no-referrer'
      }
    })
    .then(result => {
      if(result && result.status && result.status == '201' && !result.data.errorData){
        NotificationManager.success('Success saving event','', 4000, null, true);
        this.setState({
          name: '',
          email: '',
          phone: '',
          event: '',
          date: '',
          comment: '',
          errors: {
            name: false,
            email: false,
            phone: false,
            event: false,
            date: false,
            comment: false,
          },
        })
      }else if(result && result.status && result.status == '200' && result.data.errorData){
        const { errorData } = result.data;
        if(!errorData){
          throw new Error();
        }else{
          NotificationManager.error(result.data.errorData.message, 'Error saving event', 4000, null,true);
          this.setState(prevState => ({ 
            errors: {
              ...prevState.errors,
              [result.data.errorData.field]: true
            }
          }));
        }
      }else{
        throw new Error();
      }
    })
    .catch(error => {
      NotificationManager.error('Server Error','Error saving event', 4000, null, true);
    })
  }

  handleDateChange = date => this.setState({ date })

  handleCalendar = () => {

  }

  render() {
    return (
      <section className="MoreInfo">
        <NotificationContainer/>
        <form className="container" onSubmit={this.handleSubmit}>
          <img className="text-graphic" src={graphicSuperparties} alt="Superparties for all ages"/>
          <div className="title">Birthdays? Reunions? Events?</div>
          <div className="info">Comicx® is the place. More info here</div>
          <div className={`name ${this.state.errors.name ? 'error' : ''}`}><input type="text" name="name" placeholder="Name" value={this.state.name} onChange={this.handleChange}/></div>
          <div className={`email ${this.state.errors.email ? 'error' : ''}`}><input name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange}/></div>
          <div className={`phone ${this.state.errors.phone ? 'error' : ''}`}><input type="tel" name="phone" placeholder="Phone" value={this.state.phone} onChange={this.handleChange}/></div>
          <div className={`event ${this.state.errors.event ? 'error' : ''}`}><input type="text" name="event" placeholder="Event" value={this.state.event} onChange={this.handleChange}/></div>
          <div className={`calendar ${this.state.errors.date ? 'error' : ''}`} >
            { this.state.date ? 
                <DateTimePicker
                  onChange={this.handleDateChange}
                  value={this.state.date}
                  disableClock = {true}
                  isCalendarOpen = {this.state.date instanceof Date}
                  minDate = {new Date()}
                /> 
                :
                <div className="calendar-input" onClick={() => this.setState({date: new Date()})}>
                  <input name="cal" placeholder="Date" value='' onChange={() => console.log('')}/>
                </div>
            }
          </div>
          <div className={`comment ${this.state.errors.comment ? 'error' : ''}`}><textarea name="comment" placeholder="Comment" value={this.state.comment} onChange={this.handleChange}></textarea></div>
          <div className="button-cont"><button>Send</button></div>
        </form>
      </section>
    )
  }
}

export default MoreInfo