import React from 'react'
import '../scss/ThisIsComicxHeader.scss'
import Url from './Url';
const graphicThisIsComicx = `${Url.cdn}/img/elements/graphic-this-is-comicx.svg`;

const ThisIsComicxHeader = () => {
  return (
    <section className="ThisIsComicxHeader">
      <div className="container">
        <img className="text-graphic" src={graphicThisIsComicx} alt="this is comicx"/>
      </div>
    </section>
  )
}

export default ThisIsComicxHeader