import React from 'react'
import { Link, NavLink, withRouter } from "react-router-dom";
import '../scss/NavBar.scss'
import Url from './Url';
const bgLogo = `${Url.cdn}/img/elements/logo-comicx.svg`;
const smLogo = `${Url.cdn}/img/elements/logomark-comicx.svg`;
const burger = `${Url.cdn}/img/elements/button-burger.svg`;

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isToggleOn: false, active:'HOME'}

    this.toggleBurger = this.toggleBurger.bind(this)
    this.toggleBurgerOff = this.toggleBurgerOff.bind(this)
  }

  toggleBurger() {
    this.setState(state => ({ isToggleOn: !state.isToggleOn }))
  }

  toggleBurgerOff() {
    this.setState(() => ({ isToggleOn: false }))
  }

  render() {
    return (
      <div>
        <header>
          <div className="navbar container">
            <Link className="bg-logo" to="/"><img src={bgLogo} alt="ComicX"/></Link>
            <Link className="sm-logo" to="/"><img src={smLogo} alt="ComicX"/></Link>
            <div className="nav">
              <div><NavLink exact to="/" activeClassName="active">HOME</NavLink></div>
              <div><NavLink to="/menu" activeClassName="active">MENU</NavLink></div>
              <div><NavLink to="/this-is-comicx" activeClassName="active">THIS IS COMICX</NavLink></div>
              <div><NavLink to="/news" activeClassName="active">NEWS</NavLink></div> 
              <div><NavLink to="/join-us" activeClassName="active">JOIN US</NavLink></div>
            </div>
            <div className="social">
              <a href="https://www.facebook.com/ComicxUSA/" target="__blank"><i className="fab fa-facebook-square"></i></a>
              <a href="https://www.instagram.com/comicxusa/" target="__blank"><i className="fab fa-instagram"></i></a>
            </div>
            <button className="burger" onClick={this.toggleBurger}>
              <img src={burger} alt=""/>
            </button>
          </div>
          {!this.state.isToggleOn ? '' :
            <div className="menu">
              <div><NavLink exact to="/" activeClassName="active" onClick={ this.toggleBurgerOff }>HOME</NavLink></div>
              <div><NavLink to="/menu" activeClassName="active" onClick={ this.toggleBurgerOff }>MENU</NavLink></div>
              <div><NavLink to="/this-is-comicx" activeClassName="active" onClick={ this.toggleBurgerOff }>THIS IS COMICX</NavLink></div>
              <div><NavLink to="/news" activeClassName="active" onClick={ this.toggleBurgerOff }>NEWS</NavLink></div>
              <div><NavLink to="/join-us" activeClassName="active" onClick={ this.toggleBurgerOff }>JOIN US</NavLink></div>
              <div><a href="https://www.facebook.com/ComicxUSA/" target="__blank" onClick={this.toggleBurgerOff}>FACEBOOK</a></div>
              <div><a href="https://www.instagram.com/comicxusa/" target="__blank" onClick={this.toggleBurgerOff}>INSTAGRAM</a></div>
            </div>
          }
        </header>
        <div className="header-margin" />
        {!this.state.isToggleOn ? '' : <div className="menu-margin" />}
      </div>
    )
  }
}

export default withRouter(props => <NavBar {...props}/>);