import React from 'react'
import { NewsBox } from './'
import '../scss/NewsHeader.scss'
import Url from './Url';
const graphicNews = `${Url.cdn}/img/elements/graphic-find-out-what.svg`;

const NewsHeader = ({news}) => {
  if(!news) {
    return <div></div>
  }
  const {title, content, img} = news
  return (
    <div className="NewsHeader">
      <div className="container">
        <img className="text-graphic" src={graphicNews} alt="find out what everyone is saying about comicx."/>
        <div className="text-title">FIND OUT WHAT EVERYONE IS SAYING ABOUT COMICX</div>
        <NewsBox isMain={true} title={title} content={content} image={img || ''}/>
      </div>
    </div>
  )
}

export default NewsHeader