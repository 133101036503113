import React from 'react'
import '../scss/WhereSuperheroes.scss'
import Url from './Url';
const graphicWhereSuperheroes = `${Url.cdn}/img/elements/graphic-where-superheroes.svg`;

const WhereSuperheroes = () => {
  return (
    <section className="WhereSuperheroes">
      <div className="container">
        <img className="text-graphic" src={graphicWhereSuperheroes} alt="where superheroes grab a bite."/>
        <div className="box-white">With 36 locations in Mexico and over 10 million smiles created since 2010, now it’s time to open our first location in Phoenix and we’ve come to Desert Ridge Marketplace to unite all comic book lovers in a wondrous experience you’ll want to repeat over and over.</div>
        <div className="box-yellow">So grab your cape, your wand and everything you need to discover this new adventure and become part of ComicX®.</div>
      </div>
    </section>
  )
}

export default WhereSuperheroes