import React from 'react'
import {
  Switch,
  Route
} from "react-router-dom";
import Url from './components/Url'
import cdn from './api/cdnNews'
import api from './api/api'
import './scss/App.scss'

import NavBar from './components/NavBar'
import Footer from './components/Footer'

import Home from './pages/Home'
import Menu from './pages/Menu'
import ThisIsComicx from './pages/ThisIsComicx'
import JoinUs from './pages/JoinUs'
import News from './pages/News'
import NewsDetails from './pages/NewsDetails';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      unresolvedNews: [],
      news: [],
      newsFetchStep: 12,
      newsCountInit: 13,
      newsStatus: {
        error: false,
        hasMore: true,
        isLoading: false,
      }
    }

    this.loadMoreNews = this.loadMoreNews.bind(this)
    this.fetchNewsDetail = this.fetchNewsDetail.bind(this)
  }

  async fetchAllNews() {
    const { news, newsFetchStep, newsCountInit } = this.state
    const start = news.length
    const end = news.length ? `${news.length + newsFetchStep}` : `${newsCountInit}`
    const response = await api.get(`/news?_order=DESC&_sort=createdAt&_start=${start}&_end=${end}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Referrer-Policy': 'no-referrer'
      }
    })
      .catch(err => {
        this.setState({
          newsStatus: Object.assign(this.state.newsStatus, {
            error: true
          })
        })
      })
    this.setState({unresolvedNews: response.data})
    return response
  }

  async loadMoreNews() {
    this.setState({ newsStatus: Object.assign(this.state.newsStatus, { isLoading: true })})
    let unresolvedNews
    let totalNews
    if (!this.state.unresolvedNews.length) {
      let response = await this.fetchAllNews()
      totalNews = response.headers['x-total-count']
      unresolvedNews = response.data
    } else {
      unresolvedNews = this.state.unresolvedNews
    }
    const fetchNews = unresolvedNews.map(item => this.fetchNews(item))
    const resolvedNews = await this.resolveNews(fetchNews)
    const hasMore = totalNews > (this.state.news.length + resolvedNews.length)
    this.setState({
      news: [...this.state.news, ...resolvedNews],
      unresolvedNews: [],
      newsStatus: Object.assign(this.state.newsStatus, {
        isLoading: false,
        hasMore
      }),
    })
  }

  async fetchNewsDetail(newsTitle) {
    let unresolvedNews
    if (!this.state.unresolvedNews.length) {
      let response = await this.fetchAllNews()
      unresolvedNews = response.data
    } else {
      unresolvedNews = this.state.unresolvedNews
    }

    const news = unresolvedNews.find(item => {
      let titleFormat = item.title.toLowerCase().replace(' ', '-', 'gi')
      return titleFormat === newsTitle
    })

    const foundNews = await this.fetchNews(news)
    return this.resolveNews([foundNews])
  }

  fetchNews(news) {
    const content = cdn.get(`/news/content/${news.id}.txt`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Referrer-Policy': 'no-referrer'
      }
    }).catch(err => console.error('ComicX content error: ', err))
    news.content = content || null
    news.img = `${Url.cdnNews}/news/img/${news.id}.${news.imageExt || 'png'}` || ''
    return news
  }

  resolveNews(news) {
    return Promise.all(
      news.map(async news => {
        const asset = await news.content
        news.content = asset && asset.data ? asset.data : null
        return news
      })
    )
  }

  render() {
    return (
      <div>
        <NavBar />
        <div>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/menu">
              <Menu />
            </Route>
            <Route path="/this-is-comicx">
              <ThisIsComicx />
            </Route>
            <Route path="/join-us">
              <JoinUs />
            </Route>
            <Route path="/news/:title" component={({match}) => (
              <NewsDetails
                news={this.state.news}
                fetchNewsDetail={this.fetchNewsDetail}
                title={match.params.title}
              />
            )}>
            </Route>
            <Route path="/news">
              <News
                news={this.state.news}
                loadMoreNews={this.loadMoreNews}
                newsStatus={this.state.newsStatus}
              />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    )
  }
}

export default App
