import React from 'react'
import api from '../api/api'
import '../scss/JoinUs.scss'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Url from './Url';
const graphicJoinUs = `${Url.cdn}/img/elements/graphic-join-us.svg`;

class JoinUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      phone: '',
      email: '',
      kitchen: false,
      bar: false,
      other: false,
      service: false,
      host: false,
      errors: {
        name: false,
        phone: false,
        email: false,
        superpowers: false,
      },
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const field = target.name
    this.setState({[field]: value, errors: Object.assign(this.state.errors, {[field]: false})})
  }

  handleSubmit(event) {
    event.preventDefault()
    const {name, phone, email, kitchen, bar, other, service, host} = this.state
    const body = {
      name: name || undefined,
      email: email || undefined,
      phone: phone || undefined,
      superpowers: []
    }
    if(kitchen) body.superpowers.push('kitchen')
    if(bar) body.superpowers.push('bar')
    if(other) body.superpowers.push('other')
    if(service) body.superpowers.push('service')
    if(host) body.superpowers.push('host')
    api.post('/applicants', body, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Referrer-Policy': 'no-referrer'
      }
    })
    .then(result => {
      if(result && result.status && result.status == '201' && !result.data.errorData){
        NotificationManager.success('Success saving event','', 4000, null, true);
        this.setState({
          name: '',
          phone: '',
          email: '',
          kitchen: false,
          bar: false,
          other: false,
          service: false,
          host: false,
          errors: {
            name: false,
            phone: false,
            email: false,
            superpowers: false,
          },
        })
      }else if(result && result.status && result.status == '200' && result.data.errorData){
        const { errorData } = result.data;
        if(!errorData){
          throw new Error();
        }else{
          NotificationManager.error(result.data.errorData.message, 'Error saving event', 4000, null,true);
          this.setState(prevState => ({ 
            errors: {
              ...prevState.errors,
              [result.data.errorData.field]: true
            }
          }));
        }
      }else{
        throw new Error();
      }
    })
    .catch(error => {
      NotificationManager.error('Server Error','Error saving event', 4000, null, true);
    })
  }

  render() {
    return (
      <div className="JoinUs">
        <NotificationContainer/>
        <form className="container" onSubmit={this.handleSubmit}>
          <img className="text-graphic" src={graphicJoinUs} alt=""/>
          <div className="text-box" id="text-twoline">
            <div>At Comicx® we work to offer our guests a unique experience, full of magic, fun and great food.</div>
            <br/>
            <div>Are you up to the challenge? Then we want you on our team.</div>
          </div>
          <div className="text-box" id="text-oneline">
            At Comicx® we work to offer our guests a unique experience, full of magic, fun and great food. Are you up to the challenge? Then we want you on our team.
          </div>
          <div className="contact-form">
            <div className={`name ${this.state.errors.name ? 'error' : ''}`}><input name="name" placeholder="Name" value={this.state.name} onChange={this.handleChange}/></div>
            <div className={`phone ${this.state.errors.phone ? 'error' : ''}`}><input name="phone" placeholder="Phone" value={this.state.phone} onChange={this.handleChange}/></div>
            <div className={`email ${this.state.errors.email ? 'error' : ''}`}><input name="email" placeholder="Email" value={this.state.email} onChange={this.handleChange}/></div>
          </div>
          <div className="skills">
            <div className="title">Select your superpower</div>
            <div className={`select ${this.state.errors.superpowers ? 'error' : ''}`}>
              <div>
                <input
                  type="checkbox"
                  id="kitchen"
                  name="kitchen"
                  checked={this.state.kitchen}
                  onChange={this.handleChange}
                />
                <label htmlFor="kitchen">kitchen</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="bar"
                  name="bar"
                  checked={this.state.bar}
                  onChange={this.handleChange}
                />
                <label htmlFor="bar">bar</label>
              </div>
              <div id="other">
                <input
                  type="checkbox"
                  id="other"
                  name="other"
                  checked={this.state.other}
                  onChange={this.handleChange}
                />
                <label htmlFor="other">other</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="service"
                  name="service"
                  checked={this.state.service}
                  onChange={this.handleChange}
                />
                <label htmlFor="service">service</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="host"
                  name="host"
                  checked={this.state.host}
                  onChange={this.handleChange}
                />
                <label htmlFor="host">host</label>
              </div>
            </div>
            <button className="button">Send</button>
          </div>
        </form>
      </div>
    )
  }
}

export default JoinUs