import React from 'react'

class Map extends React.Component {
  static defaultProps = {
    center: {
      lat: 33.67,
      lng: -111.97
    },
    zoom: 15
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        <iframe
          title="ComicX.us"
          width="100%"
          height="100%"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d392.0892947884572!2d-111.975912454148!3d33.677128425581024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x84d7d173e5adc53!2sComicx%20Restaurant%20Bar%20%26%20Store!5e0!3m2!1ses-419!2smx!4v1575354059050!5m2!1ses-419!2smx"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        />
      </div>
    );
  }
}

export default Map