import React from 'react'
import { Menu as MenuComponent } from '../components'
import history from '../history'

const Menu = () => {
  window.scrollTo(0,0);
  return (
    <div className="MenuPage">
      <MenuComponent />
    </div>
  )
}

export default Menu