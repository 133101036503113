import React from 'react'
import '../scss/PaperCrop.scss'
import Url from './Url';
const paperCrop = `${Url.cdn}/img/elements/paper-crop-min.png`;

const PaperCrop = () => {
  return (
    <img className="PaperCrop" src={paperCrop} alt=""/>
  )
}

export default PaperCrop