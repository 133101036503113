import React from 'react'
import { Map } from './'
import '../scss/FindUs.scss'
import Url from './Url';
const graphicFindUs = `${Url.cdn}/img/elements/graphic-find-us.svg`;

const FindUs = () => (
  <section className="FindUs">
    <div className="container">
      <img className="text-graphic" src={graphicFindUs} alt="Find us"/>
      <div className="contact-info">
        <div className="address">Desert Ridge Marketplace<br/>21001 N. Tatum Blvd. Suite 95<br/>Phoenix,  AZ 85050</div>
        <div className="contact-list">
          <div className="contact-element contact-phone">
            <a className="contact-logo" href="" target="__blank"><i className="fas fa-phone-alt"></i></a>
            <span className="contact-value phone">480-306-6780</span>
          </div>
          <div className="contact-element contact-email">
            <a className="contact-logo" href="mailto:hello@comicx.us?subject=Hello%20Comicx" target="__blank"><i className="far fa-envelope"></i></a>
            {/* <a className="contact-logo" href="" target="__blank"><i className="far fa-envelope"></i></a> */}
            <a className="contact-value email" href="mailto:hello@comicx.us?subject=Hello%20Comicx">HELLO@COMICX.US</a>
            {/* <span className="contact-value email">HELLO@COMICX.COM</span> */}
          </div>
          <div className="contact-element contact-social">
            <a className="contact-logos" href="https://www.facebook.com/ComicxUSA/" target="__blank"><i className="fab fa-facebook-square"></i></a>
            <a className="contact-logos" href="https://www.instagram.com/comicxusa/" target="__blank"><i className="fab fa-instagram"></i></a>
            <span className="contact-value facebook-instagram">COMICXUSA</span>
          </div>
        </div>
      </div>
      <div className="map">
        <Map />
      </div>
    </div>
  </section>
)

export default FindUs