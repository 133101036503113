import React from 'react'
import '../scss/Footer.scss'
import Url from './Url';
const bgLogo = `${Url.cdn}/img/elements/logo-comicx.svg`;
const smLogo = `${Url.cdn}/img/elements/logomark-comicx.svg`;

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="container">
        <div className="address">Desert Ridge Marketplace 21001 N. Tatum Blvd. Suite 95, Phoenix, AZ 85050</div>
        <div className="contact-list">
          <div className="contact-element contact-phone">
            <a className="contact-logo" href="" target="__blank"><i className="fas fa-phone-alt"></i></a>
            <span className="contact-value phone">480-306-6780</span>
          </div>
          <div className="contact-element contact-email">
            <a className="contact-logo" href="mailto:hello@comicx.us?subject=Hello%20Comicx" target="__blank"><i className="far fa-envelope"></i></a>
            <a className="contact-value email" href="mailto:hello@comicx.us?subject=Hello%20Comicx">HELLO@COMICX.US</a>
          </div>
          <div className="contact-element contact-social">
            <a className="contact-logo" href="https://www.facebook.com/ComicxUSA/" target="__blank"><i className="fab fa-facebook-square"></i></a>
            <a className="contact-logo" href="https://www.instagram.com/comicxusa/" target="__blank"><i className="fab fa-instagram"></i></a>
            <span className="contact-value facebook-instagram">COMICXUSA</span>
          </div>
        </div>
        <img src={bgLogo} className="bg-logo" alt=""/>
        <img src={smLogo} className="sm-logo" alt=""/>
      </div>
    </footer>
  )
}

export default Footer